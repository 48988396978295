/*
A VERTICAL event
*/
.fc-v-event {
  display: block; }
  .fc-v-event .fc-event-main {
    height: 100%; }
  .fc-v-event .fc-event-main-frame {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .fc-v-event .fc-event-time {
    flex-grow: 0;
    flex-shrink: 0;
    max-height: 100%;
    overflow: hidden; }
  .fc-v-event .fc-event-title-container {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0; }
  .fc-v-event .fc-event-title {
    top: 0;
    bottom: 0;
    max-height: 100%;
    overflow: hidden; }
  .fc-v-event:not(.fc-event-start) {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .fc-v-event:not(.fc-event-end) {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .fc-v-event.fc-event-selected:before {
    left: -10px;
    right: -10px; }
  .fc-v-event .fc-event-resizer-start {
    cursor: n-resize; }
  .fc-v-event .fc-event-resizer-end {
    cursor: s-resize; }
  .fc-v-event:not(.fc-event-selected) .fc-event-resizer {
    height: var(--fc-event-resizer-thickness);
    left: 0;
    right: 0; }
  .fc-v-event:not(.fc-event-selected) .fc-event-resizer-start {
    top: calc(var(--fc-event-resizer-thickness) / -2); }
  .fc-v-event:not(.fc-event-selected) .fc-event-resizer-end {
    bottom: calc(var(--fc-event-resizer-thickness) / -2); }
  .fc-v-event.fc-event-selected .fc-event-resizer {
    left: 50%;
    margin-left: calc(var(--fc-event-resizer-dot-total-width) / -2); }
  .fc-v-event.fc-event-selected .fc-event-resizer-start {
    top: calc(var(--fc-event-resizer-dot-total-width) / -2); }
  .fc-v-event.fc-event-selected .fc-event-resizer-end {
    bottom: calc(var(--fc-event-resizer-dot-total-width) / -2); }

.fc .fc-timegrid .fc-daygrid-body {
  z-index: 2; }

.fc .fc-timegrid-divider {
  padding: 0 0 2px; }

.fc .fc-timegrid-body {
  position: relative;
  z-index: 1;
  min-height: 100%; }

.fc .fc-timegrid-axis-chunk {
  position: relative; }
  .fc .fc-timegrid-axis-chunk > table {
    position: relative;
    z-index: 1; }

.fc .fc-timegrid-slots {
  position: relative;
  z-index: 1; }

.fc .fc-timegrid-slot {
  height: 1.5em;
  border-bottom: 0; }
  .fc .fc-timegrid-slot:empty:before {
    content: '\00a0'; }

.fc .fc-timegrid-slot-minor {
  border-top-style: dotted; }

.fc .fc-timegrid-slot-label-cushion {
  display: inline-block;
  white-space: nowrap; }

.fc .fc-timegrid-slot-label {
  vertical-align: middle; }

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  padding: 0 4px; }

.fc .fc-timegrid-axis-frame-liquid {
  height: 100%; }

.fc .fc-timegrid-axis-frame {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.fc .fc-timegrid-axis-cushion {
  max-width: 60px;
  flex-shrink: 0; }

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: right; }

.fc-direction-rtl .fc-timegrid-slot-label-frame {
  text-align: left; }

.fc-liquid-hack .fc-timegrid-axis-frame-liquid {
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.fc .fc-timegrid-col.fc-day-today {
  background-color: var(--fc-today-bg-color); }

.fc .fc-timegrid-col-frame {
  min-height: 100%;
  position: relative; }

.fc-liquid-hack .fc-timegrid-col-frame {
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.fc-media-screen .fc-timegrid-cols {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .fc-media-screen .fc-timegrid-cols > table {
    height: 100%; }

.fc-media-screen .fc-timegrid-col-bg,
.fc-media-screen .fc-timegrid-col-events,
.fc-media-screen .fc-timegrid-now-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-media-screen .fc-timegrid-event-harness {
  position: absolute; }

.fc .fc-timegrid-col-bg {
  z-index: 2; }
  .fc .fc-timegrid-col-bg .fc-non-business {
    z-index: 1; }
  .fc .fc-timegrid-col-bg .fc-bg-event {
    z-index: 2; }
  .fc .fc-timegrid-col-bg .fc-highlight {
    z-index: 3; }

.fc .fc-timegrid-bg-harness {
  position: absolute;
  left: 0;
  right: 0; }

.fc .fc-timegrid-col-events {
  z-index: 3; }

.fc .fc-timegrid-now-indicator-container {
  bottom: 0;
  overflow: hidden; }

.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 2.5% 0 2px; }

.fc-direction-rtl .fc-timegrid-col-events {
  margin: 0 2px 0 2.5%; }

.fc-timegrid-event.fc-event-mirror {
  box-shadow: 0px 0px 0px 1px var(--fc-page-bg-color); }

.fc-timegrid-event {
  font-size: var(--fc-small-font-size);
  border-radius: 3px; }
  .fc-timegrid-event .fc-event-main {
    padding: 1px 1px 0; }
  .fc-timegrid-event .fc-event-time {
    white-space: nowrap;
    font-size: var(--fc-small-font-size);
    margin-bottom: 1px; }

.fc-timegrid-event-condensed .fc-event-main-frame {
  flex-direction: row;
  overflow: hidden; }

.fc-timegrid-event-condensed .fc-event-time:after {
  content: "\00a0-\00a0"; }

.fc-timegrid-event-condensed .fc-event-title {
  font-size: var(--fc-small-font-size); }

.fc-media-screen .fc-timegrid-event {
  position: absolute;
  top: 0;
  bottom: 1px;
  left: 0;
  right: 0; }

.fc .fc-timegrid-now-indicator-line {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  border-style: solid;
  border-color: var(--fc-now-indicator-color);
  border-width: 1px 0 0; }

.fc .fc-timegrid-now-indicator-arrow {
  position: absolute;
  z-index: 4;
  margin-top: -5px;
  border-style: solid;
  border-color: var(--fc-now-indicator-color); }

.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.fc-direction-rtl .fc-timegrid-now-indicator-arrow {
  right: 0;
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent; }
