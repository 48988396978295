:root {
  --fc-daygrid-event-dot-width: 8px; }

.fc .fc-popover {
  position: fixed;
  top: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); }

.fc .fc-popover-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 4px; }

.fc .fc-popover-title {
  margin: 0 2px; }

.fc .fc-popover-close {
  cursor: pointer;
  opacity: 0.65;
  font-size: 1.1em; }

.fc-theme-standard .fc-popover {
  border: 1px solid var(--fc-border-color);
  background: var(--fc-page-bg-color); }

.fc-theme-standard .fc-popover-header {
  background: var(--fc-neutral-bg-color); }

.fc-daygrid-day-frame:before,
.fc-daygrid-day-events:before,
.fc-daygrid-event-harness:before {
  content: "";
  clear: both;
  display: table; }

.fc-daygrid-day-frame:after,
.fc-daygrid-day-events:after,
.fc-daygrid-event-harness:after {
  content: "";
  clear: both;
  display: table; }

.fc .fc-daygrid-body {
  position: relative;
  z-index: 1; }

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--fc-today-bg-color); }

.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%; }

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse; }

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.3; }

.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px; }

.fc .fc-daygrid-day-events {
  margin-top: 1px; }

.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  position: absolute;
  left: 0;
  right: 0; }

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: relative;
  min-height: 2em; }

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 1em; }

.fc .fc-daygrid-event-harness {
  position: relative; }

.fc .fc-daygrid-event-harness-abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc .fc-daygrid-bg-harness {
  position: absolute;
  top: 0;
  bottom: 0; }

.fc .fc-daygrid-day-bg .fc-non-business {
  z-index: 1; }

.fc .fc-daygrid-day-bg .fc-bg-event {
  z-index: 2; }

.fc .fc-daygrid-day-bg .fc-highlight {
  z-index: 3; }

.fc .fc-daygrid-event {
  z-index: 6;
  margin-top: 1px; }

.fc .fc-daygrid-event.fc-event-mirror {
  z-index: 7; }

.fc .fc-daygrid-day-bottom {
  font-size: 0.85em;
  margin: 2px 3px 0; }

.fc .fc-daygrid-more-link {
  position: relative;
  z-index: 4;
  cursor: pointer; }

.fc .fc-daygrid-week-number {
  position: absolute;
  z-index: 5;
  top: 0;
  padding: 2px;
  min-width: 1.5em;
  text-align: center;
  background-color: var(--fc-neutral-bg-color);
  color: var(--fc-neutral-text-color); }

.fc .fc-more-popover {
  z-index: 8; }

.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px; }

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 2px; }

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 2px; }

.fc-direction-ltr .fc-daygrid-week-number {
  left: 0;
  border-radius: 0 0 3px 0; }

.fc-direction-rtl .fc-daygrid-week-number {
  right: 0;
  border-radius: 0 0 0 3px; }

.fc-liquid-hack .fc-daygrid-day-frame {
  position: static; }

.fc-daygrid-event {
  position: relative;
  white-space: nowrap;
  border-radius: 3px;
  font-size: var(--fc-small-font-size); }

.fc-daygrid-block-event .fc-event-time {
  font-weight: bold; }

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 1px; }

.fc-daygrid-dot-event {
  display: flex;
  align-items: center;
  padding: 2px 0; }
  .fc-daygrid-dot-event .fc-event-title {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    overflow: hidden;
    font-weight: bold; }
  .fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
    background: rgba(0, 0, 0, 0.1); }
  .fc-daygrid-dot-event.fc-event-selected:before {
    top: -10px;
    bottom: -10px; }

.fc-daygrid-event-dot {
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: calc(var(--fc-daygrid-event-dot-width) / 2) solid var(--fc-event-border-color);
  border-radius: calc(var(--fc-daygrid-event-dot-width) / 2); }

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px; }

.fc-direction-rtl .fc-daygrid-event .fc-event-time {
  margin-left: 3px; }
