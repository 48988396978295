html,
body,
#root {
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-size: 14px;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.fc-col-header-cell {
  position: relative;
}

tfoot th,
tfoot td {
  background: #fafafa;
}

.circle-picker {
  width: 100% !important;
}

.fc-timegrid-col.fc-day-today {
  background-color: #edf1fb !important;
}

.ReactVirtualized__Table__headerRow {
  display: flex;
  align-items: center;
  padding-right: 0px !important;
}

.ReactVirtualized__Table__row {
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgb(238, 243, 245);
}

.ReactVirtualized__Table__rowColumn {
  flex: 1;
}

/* Fix: on Chrome > v91.0.4472.77 the grid is not visible */
.fc .fc-scrollgrid-section {
  height: 1px !important;
}

.webhooks-form .chakra-collapse {
  overflow: visible !important;
}

.menu-portal {
  position: absolute;
  z-index: 101;
}

#tidio-chat-iframe {
  bottom: 0px !important;
}
